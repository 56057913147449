define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-follow-btn", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "@ember/string", "discourse/components/d-button", "I18n", "discourse/plugins/discourse-activity-pub/discourse/components/modal/activity-pub-actor-follow", "discourse/plugins/discourse-activity-pub/discourse/components/modal/activity-pub-actor-reject", "discourse/plugins/discourse-activity-pub/discourse/components/modal/activity-pub-actor-unfollow", "discourse/plugins/discourse-activity-pub/discourse/components/modal/activity-pub-follow", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _string, _dButton, _I18n, _activityPubActorFollow, _activityPubActorReject, _activityPubActorUnfollow, _activityPubFollow, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const modalMap = {
    follow: _activityPubFollow.default,
    actor_follow: _activityPubActorFollow.default,
    actor_unfollow: _activityPubActorUnfollow.default,
    actor_reject: _activityPubActorReject.default
  };
  class ActivityPubFollowBtn extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    showModal() {
      this.modal.show(modalMap[this.args.type], {
        model: this.args
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "showModal", [_object.action]))();
    get class() {
      return `activity-pub-${(0, _string.dasherize)(this.args.type)}-btn`;
    }
    get label() {
      return _I18n.default.t(`discourse_activity_pub.${this.args.type}.label`);
    }
    get title() {
      return _I18n.default.t(`discourse_activity_pub.${this.args.type}.title`, {
        actor: this.args.actor?.name
      });
    }
    get icon() {
      switch (this.args.type) {
        case "follow":
          return "external-link-alt";
        case "actor_follow":
          return "plus";
        case "actor_unfollow":
          return "";
        default:
          return "";
      }
    }
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DButton
          @icon={{this.icon}}
          @action={{this.showModal}}
          @translatedLabel={{this.label}}
          @translatedTitle={{this.title}}
          class={{this.class}}
        />
      
    */
    {
      "id": "1+IzcyTb",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[16,0,[30,0,[\"class\"]]]],[[\"@icon\",\"@action\",\"@translatedLabel\",\"@translatedTitle\"],[[30,0,[\"icon\"]],[30,0,[\"showModal\"]],[30,0,[\"label\"]],[30,0,[\"title\"]]]],null],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/app/code/app/assets/javascripts/discourse/discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-follow-btn.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ActivityPubFollowBtn;
});