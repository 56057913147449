define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-site-setting-notice", ["exports", "@glimmer/component", "@ember/service", "@ember/string", "discourse-common/helpers/d-icon", "discourse-common/lib/get-url", "I18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _string, _dIcon, _getUrl, _I18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ActivityPubSiteSettingNotice extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    get containerClass() {
      return `activity-pub-site-setting ${(0, _string.dasherize)(this.args.setting)}`;
    }
    get label() {
      return _I18n.default.t(`admin.discourse_activity_pub.actor.site_setting.${this.args.setting}.label`);
    }
    get title() {
      return _I18n.default.t(`admin.discourse_activity_pub.actor.site_setting.${this.args.setting}.title`);
    }
    get description() {
      return _I18n.default.t(`admin.discourse_activity_pub.actor.site_setting.${this.args.setting}.description`, {
        model_type: this.args.modelType
      });
    }
    get url() {
      return (0, _getUrl.default)(`/admin/site_settings/category/all_results?filter=${this.args.setting}`);
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class={{this.containerClass}} title={{this.title}}>
          <div class="activity-pub-site-setting-label">
            <a href={{this.url}}>{{icon "cog"}}{{this.label}}</a>
          </div>
          <span>{{this.description}}</span>
        </div>
      
    */
    {
      "id": "UPmzyYZs",
      "block": "[[[1,\"\\n    \"],[10,0],[15,0,[30,0,[\"containerClass\"]]],[15,\"title\",[30,0,[\"title\"]]],[12],[1,\"\\n      \"],[10,0],[14,0,\"activity-pub-site-setting-label\"],[12],[1,\"\\n        \"],[10,3],[15,6,[30,0,[\"url\"]]],[12],[1,[28,[32,0],[\"cog\"],null]],[1,[30,0,[\"label\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,1],[12],[1,[30,0,[\"description\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/app/code/app/assets/javascripts/discourse/discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-site-setting-notice.js",
      "scope": () => [_dIcon.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ActivityPubSiteSettingNotice;
});